import { zodResolver } from "@hookform/resolvers/zod";

import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { InboxConfiguration, InboxConfigurationBase } from "@/types";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { CaretSortIcon, PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { CheckIcon, X } from "lucide-react";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import {
  checkAddressAvailability,
  getInboxDomains,
  upsertInboxConfiguration,
} from "@/utils/apiCalls";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { formatSecretPreview, SecretPopup } from "@/components/SecretDialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useNavigate } from "react-router-dom";
import { Drawer, DrawerHeader, DrawerTitle } from "@/components/ui/drawer";
import { DrawerContent } from "@/components/ui/drawer";
import { useIsMobile } from "@/hooks/use-mobile";
import { useUserContext } from "@/contexts/UserContext";

const inboxFormSchema = z.object({
  name: z.string(),
  email_address: z.string(),
  spf_pass_required: z.boolean(),
  dkim_pass_required: z.boolean(),
  dmarc_pass_required: z.boolean(),
  allow_cc: z.boolean(),
  allow_bcc: z.boolean(),
  allowed_from_addresses: z
    .object({
      addresses: z.array(z.string()),
      domains: z.array(z.string()),
    })
    .optional(),
  blocked_from_addresses: z
    .object({
      addresses: z.array(z.string()),
      domains: z.array(z.string()),
    })
    .optional(),
  webhook_url: z.union([z.string().url(), z.literal("")]).optional(),
  webhook_signing_secret_id: z.string().optional(),
  open_and_click_tracking: z.boolean(),
});

const DomainSelector = (props: {
  domainValue: string | undefined;
  onDomainChange: (domain: string) => void;
}) => {
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const [domains, setDomains] = useState<{ name: string; verified: boolean }[]>(
    []
  );

  useEffect(() => {
    getInboxDomains(authInfo.accessToken ?? null).then((data) => {
      if (data) {
        const verifiedDomains = data.filter((domain) => domain.verified);
        setDomains(verifiedDomains);
        props.onDomainChange(props.domainValue || verifiedDomains[0].name);
      } else {
        toast.error("Failed to fetch inbox domains");
      }
    });
  }, [authInfo.accessToken]);

  return (
    <Select value={props.domainValue} onValueChange={props.onDomainChange}>
      <SelectTrigger className="truncate text-ellipsis w-[300px]">
        <SelectValue placeholder="Select Domain" />
      </SelectTrigger>
      <SelectContent>
        <div className="overflow-y-scroll max-h-[200px]">
          {domains.map((domain) => (
            <SelectItem key={domain.name} value={domain.name}>
              {domain.name}
            </SelectItem>
          ))}
          <Button
            variant="ghost"
            className="pl-0 w-full"
            onClick={() => {
              navigate("/domain");
            }}
          >
            <PlusIcon className="mr-2 h-4 w-4 inline" />
            Custom Domain
          </Button>
        </div>
      </SelectContent>
    </Select>
  );
};

const WebhookAuthenticationComponent = (props: {
  form: UseFormReturn<any>;
  update: boolean;
}) => {
  const { webhookSigningSecrets } = useUserContext();
  return (
    <div className="space-y-1">
      <>
        {webhookSigningSecrets.length > 0 ? (
          <FormField
            control={props.form.control}
            name="webhook_signing_secret_id"
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value as any);
                  }}
                  value={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Webhook Secret" />
                  </SelectTrigger>
                  <SelectContent>
                    {webhookSigningSecrets.map((secret) => (
                      <SelectItem key={secret.id} value={secret.id}>
                        {secret.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        ) : (
          <div className="text-xs text-muted-foreground">
            Once {props.update ? "you update" : "you create"} the inbox, you
            will be able to copy the webhook signing secret
          </div>
        )}
      </>
      <FormDescription>
        The webhook signing secret is used to verify the authenticity of the
        email payload that is delivered to the webhook URL. See more information{" "}
        <a
          href="https://docs.botmailroom.com/documentation/receiving-emails-via-webhook#configuring-and-validating-webhooks"
          target="_blank"
          className="underline text-blue-500"
          rel="noreferrer"
        >
          here
        </a>
      </FormDescription>
    </div>
  );
};

const createControlObject = (values: string[]) => {
  const addresses = values.filter((value) => value.includes("@"));
  const domains = values.filter((value) => !value.includes("@"));
  return { addresses, domains };
};

const MultiItemField = (props: {
  form: UseFormReturn<any>;
  fieldName: string;
  fieldLabel: string;
  description: string;
}) => {
  const [fieldsInputValue, setFieldsInputValue] = useState<string>("");

  return (
    <FormField
      control={props.form.control}
      name={props.fieldName}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{props.fieldLabel}</FormLabel>
          <FormControl>
            <Input
              type="text"
              autoComplete="off"
              {...field}
              value={fieldsInputValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const currentValue = e.currentTarget.value;
                  e.preventDefault();
                  e.stopPropagation();
                  if (currentValue) {
                    const fieldsToAdd = currentValue.split("|");
                    const newFields = createControlObject(fieldsToAdd);
                    props.form.setValue(props.fieldName, {
                      addresses: [
                        ...(props.form.getValues(props.fieldName)?.addresses ??
                          []),
                        ...newFields.addresses,
                      ],
                      domains: [
                        ...(props.form.getValues(props.fieldName)?.domains ??
                          []),
                        ...newFields.domains,
                      ],
                    });
                    setFieldsInputValue("");
                  }
                }
              }}
              onChange={(e) => {
                setFieldsInputValue(e.target.value);
              }}
              placeholder="Enter emails and domains here..."
            />
          </FormControl>
          <MultiItemsDisplay
            items={
              props.form
                .getValues(props.fieldName)
                ?.addresses.concat(
                  props.form.getValues(props.fieldName)?.domains
                ) ?? []
            }
            setItems={(items) => {
              const newFields = createControlObject(items);
              props.form.setValue(props.fieldName, newFields);
            }}
          />
          <FormDescription>{props.description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const MultiItemsDisplay = (props: {
  items: string[];
  setItems: (items: string[]) => void;
}) => {
  return (
    <div className="space-x-1">
      {props.items.map((item) => (
        <Badge key={item} variant="secondary">
          {item}
          {props.setItems && (
            <button
              className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
              onClick={(e) => {
                e.stopPropagation();
                props.setItems(props.items.filter((v) => v !== item));
              }}
            >
              <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
            </button>
          )}
        </Badge>
      ))}
    </div>
  );
};

export const InboxForm = (props: {
  existingConfiguration?: InboxConfigurationBase;
  onSubmitSuccessCallback?: (
    inbox: InboxConfiguration,
    newWebhookSigningSecret: boolean
  ) => void;
}) => {
  const authInfo = useAuthInfo();
  const isMobile = useIsMobile();
  const { setInboxes, webhookSigningSecrets, loadWebhookSigningSecrets } =
    useUserContext();
  const [loading, setLoading] = useState(false);
  const [checkAddressLoading, setCheckAddressLoading] = useState(false);
  const [webhookSigningSecret, setWebhookSigningSecret] = useState<
    string | null
  >(null);
  const [domain, setDomain] = useState<string>(
    props.existingConfiguration?.email_address?.split("@")[1] ??
      "inbox.botmailroom.com"
  );

  const handleCheckAddressAvailability = async (
    email: string,
    displayToast: boolean = true
  ) => {
    setCheckAddressLoading(true);
    const response = await checkAddressAvailability(
      email,
      props.existingConfiguration?.id ?? null,
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      if (response.available) {
        if (displayToast) {
          toast.success("Address is available");
        }
      } else {
        form.setError("email_address", {
          type: "manual",
          message: "Address is already in use",
        });
      }
    } else {
      toast.error("Unable to check address availability");
    }
    setCheckAddressLoading(false);
    return response?.available ?? false;
  };

  const form = useForm<z.infer<typeof inboxFormSchema>>({
    resolver: zodResolver(inboxFormSchema),
    defaultValues: {
      name: props.existingConfiguration?.name ?? undefined,
      email_address:
        props.existingConfiguration?.email_address.split("@")[0] ?? undefined,
      webhook_url: props.existingConfiguration?.webhook_url ?? undefined,
      allow_cc: props.existingConfiguration?.allow_cc ?? true,
      allow_bcc: props.existingConfiguration?.allow_bcc ?? true,
      spf_pass_required: props.existingConfiguration?.spf_pass_required ?? true,
      dkim_pass_required:
        props.existingConfiguration?.dkim_pass_required ?? true,
      dmarc_pass_required:
        props.existingConfiguration?.dmarc_pass_required ?? true,
      allowed_from_addresses:
        props.existingConfiguration?.allowed_from_addresses ?? undefined,
      blocked_from_addresses:
        props.existingConfiguration?.blocked_from_addresses ?? undefined,
      webhook_signing_secret_id:
        props.existingConfiguration?.webhook_signing_secret_id ??
        webhookSigningSecrets[0]?.id ??
        undefined,
      open_and_click_tracking:
        props.existingConfiguration?.open_and_click_tracking ?? false,
    },
    mode: "all",
  });

  const onSubmit = async (data: z.infer<typeof inboxFormSchema>) => {
    setLoading(true);
    const emailAddress = `${data.email_address}@${domain}`;
    const addressAvailablePass = await handleCheckAddressAvailability(
      emailAddress,
      false
    );
    if (!addressAvailablePass) {
      setLoading(false);
      return;
    }
    const dataToSend = {
      name: data.name,
      spf_pass_required: data.spf_pass_required,
      dkim_pass_required: data.dkim_pass_required,
      dmarc_pass_required: data.dmarc_pass_required,
      allowed_from_addresses: data.allowed_from_addresses ?? null,
      blocked_from_addresses: data.blocked_from_addresses ?? null,
      allow_cc: data.allow_cc,
      allow_bcc: data.allow_bcc,
      webhook_url: data.webhook_url || null,
      email_address: emailAddress,
      id: props.existingConfiguration?.id ?? null,
      webhook_signing_secret_id: data.webhook_signing_secret_id ?? null,
      open_and_click_tracking: data.open_and_click_tracking,
    };
    const response = await upsertInboxConfiguration(
      dataToSend,
      authInfo.accessToken ?? null
    );
    setLoading(false);
    if (response !== null) {
      toast.success("Inbox configuration updated");

      if (response.webhook_secret) {
        setWebhookSigningSecret(response.webhook_secret);
        loadWebhookSigningSecrets();
      }

      const newInbox: InboxConfiguration = {
        ...dataToSend,
        updated_at: new Date().toISOString().slice(0, -1),
        id: response.id,
        webhook_signing_secret_id: response.webhook_signing_secret_id,
      };

      setInboxes((prev) => {
        if (prev === null) {
          return [newInbox];
        }
        return [newInbox, ...prev.filter((v) => v.id !== newInbox.id)];
      });

      if (props.onSubmitSuccessCallback) {
        props.onSubmitSuccessCallback(
          newInbox,
          response.webhook_secret != null
        );
      }
    }
  };

  return (
    <>
      <SecretPopup
        secret={webhookSigningSecret}
        setSecret={setWebhookSigningSecret}
        secretName="webhook signing secret"
      />
      <Form {...form}>
        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Inbox Name</FormLabel>
                <FormControl>
                  <Input type="text" autoComplete="off" {...field} />
                </FormControl>
                <FormDescription>Name of the inbox</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email_address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email Address</FormLabel>
                <FormControl>
                  <div
                    className={cn(
                      isMobile ? "space-y-1" : "flex items-center space-x-2"
                    )}
                  >
                    <Input
                      className="w-full"
                      type="text"
                      autoComplete="off"
                      {...field}
                    />
                    <div className="flex items-center space-x-2">
                      <Button variant="ghost" size="icon" disabled={true}>
                        @
                      </Button>
                      <DomainSelector
                        domainValue={domain}
                        onDomainChange={(domain) => {
                          setDomain(domain);
                        }}
                      />
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="secondary"
                            size="icon"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              handleCheckAddressAvailability(
                                `${field.value}@${domain}`
                              );
                            }}
                            disabled={checkAddressLoading}
                          >
                            {checkAddressLoading ? (
                              <ReloadIcon className="w-4 h-4 animate-spin" />
                            ) : (
                              <CheckIcon className="w-4 h-4" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          Check if the address is available
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </div>
                </FormControl>
                <FormDescription>
                  The email address of the inbox (the `to` field when you are
                  sent an email)
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="space-y-1">
            <FormField
              control={form.control}
              name="webhook_url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Webhook URL</FormLabel>
                  <FormControl>
                    <Input type="text" autoComplete="off" {...field} />
                  </FormControl>
                  {!field.value && (
                    <FormDescription>
                      Optional. The URL to send webhooks to when an email is
                      received.
                    </FormDescription>
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />
            {form.getValues("webhook_url") && (
              <WebhookAuthenticationComponent
                form={form}
                update={props.existingConfiguration !== undefined}
              />
            )}
          </div>
          <FormField
            control={form.control}
            name="open_and_click_tracking"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row items-start space-x-3 space-y-0">
                  <FormLabel>Open and Click Tracking</FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        const value =
                          typeof checked === "boolean" ? checked : true;
                        field.onChange(value);
                      }}
                    />
                  </FormControl>
                </div>
                <FormDescription>
                  Default setting for emails sent from this inbox. This can be
                  overridden on a per-email basis.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Collapsible>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" className="pl-0">
                Authentication Settings
                <CaretSortIcon className="h-4 w-4" />
                <span className="sr-only">Toggle</span>
              </Button>
            </CollapsibleTrigger>
            <CollapsibleContent className="space-y-4 pt-4">
              <FormField
                control={form.control}
                name="allow_cc"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex flex-row items-start space-x-3 space-y-0">
                      <FormLabel>Allow Cc</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            const value =
                              typeof checked === "boolean" ? checked : true;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </div>
                    <FormDescription>
                      Allow the `email_address` to be in the `cc` field of the
                      email. If this is false, emails received where the
                      `email_address` is Cced will be rejected
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="allow_bcc"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex flex-row items-start space-x-3 space-y-0">
                      <FormLabel>Allow Bcc</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            const value =
                              typeof checked === "boolean" ? checked : true;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </div>
                    <FormDescription>
                      Allow the `email_address` to be in the `bcc` field of the
                      email. If this is false, emails received where the
                      `email_address` is Bcced will be rejected
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="spf_pass_required"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex flex-row items-start space-x-3 space-y-0">
                      <FormLabel>SPF Pass Required</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            const value =
                              typeof checked === "boolean" ? checked : true;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </div>
                    <FormDescription>
                      See{" "}
                      <a
                        href="https://www.cloudflare.com/learning/dns/dns-records/dns-spf-record/"
                        target="_blank"
                        className="underline text-blue-500"
                        rel="noreferrer"
                      >
                        here
                      </a>{" "}
                      for more information on SPF
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dkim_pass_required"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex flex-row items-start space-x-3 space-y-0">
                      <FormLabel>DKIM Pass Required</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            const value =
                              typeof checked === "boolean" ? checked : true;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </div>
                    <FormDescription>
                      See{" "}
                      <a
                        href="https://www.cloudflare.com/learning/dns/dns-records/dns-dkim-record/"
                        target="_blank"
                        className="underline text-blue-500"
                        rel="noreferrer"
                      >
                        here
                      </a>{" "}
                      for more information on DKIM
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dmarc_pass_required"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex flex-row items-start space-x-3 space-y-0">
                      <FormLabel>DMARC Pass Required</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            const value =
                              typeof checked === "boolean" ? checked : true;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </div>
                    <FormDescription>
                      See{" "}
                      <a
                        href="https://www.cloudflare.com/learning/email-security/dmarc-explained/"
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-blue-500"
                      >
                        here
                      </a>{" "}
                      for more information on DMARC
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <MultiItemField
                form={form}
                fieldName="allowed_from_addresses"
                fieldLabel="Allowed From Addresses"
                description="The addresses that are allowed to send emails to this inbox. Leave blank to allow all addresses. You can pass in both specific addresses (e.g. `hello@example.com`) and domains (e.g. `example.com`). If you want to pass in multiple at once, separate them with a `|` character. Press `Enter` to add an address or domain."
              />
              <MultiItemField
                form={form}
                fieldName="blocked_from_addresses"
                fieldLabel="Blocked From Addresses"
                description="The addresses that are blocked from sending emails to this inbox. Leave blank to allow all addresses. You can pass in both specific addresses (e.g. `hello@example.com`) and domains (e.g. `example.com`). If you want to pass in multiple at once, separate them with a `|` character. Press `Enter` to add an address or domain."
              />
            </CollapsibleContent>
          </Collapsible>
          <Button type="submit" disabled={loading}>
            {props.existingConfiguration?.id ? "Update" : "Create"}
            {loading && <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />}
          </Button>
        </form>
      </Form>
    </>
  );
};

const InboxDialog = (props: {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent
        className="max-w-[600px] max-h-[90%] overflow-y-scroll"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Inbox Configuration</DialogTitle>
        </DialogHeader>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

const InboxDrawer = (props: {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Drawer open={props.open} onOpenChange={props.setOpen}>
      <DrawerContent className="h-[90%]">
        <DrawerHeader>
          <DrawerTitle>Inbox Configuration</DrawerTitle>
        </DrawerHeader>
        <div className="p-4 space-y-5 overflow-y-auto">{props.children}</div>
      </DrawerContent>
    </Drawer>
  );
};

export const InboxPopup = (props: {
  existingConfiguration?: InboxConfigurationBase;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const isMobile = useIsMobile();
  const inboxComponent = (
    <InboxForm
      existingConfiguration={props.existingConfiguration}
      onSubmitSuccessCallback={(_, newWebhookSigningSecret) => {
        if (!newWebhookSigningSecret) {
          props.setOpen(false);
        }
      }}
    />
  );
  return isMobile ? (
    <InboxDrawer open={props.open} setOpen={props.setOpen}>
      {inboxComponent}
    </InboxDrawer>
  ) : (
    <InboxDialog open={props.open} setOpen={props.setOpen}>
      {inboxComponent}
    </InboxDialog>
  );
};

export const EmptyInboxState = () => {
  const isMobile = useIsMobile();
  return (
    <div className="flex justify-center">
      <Card className={cn("w-[600px]", isMobile ? "w-full" : "")}>
        <CardHeader>
          <CardTitle>Create an Inbox</CardTitle>
          <CardDescription>
            Create your first inbox to start receiving emails
          </CardDescription>
        </CardHeader>
        <CardContent>
          <InboxForm
            onSubmitSuccessCallback={(_, newWebhookSigningSecret) => {
              if (!newWebhookSigningSecret) {
                window.location.reload();
              }
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};
