import "./assets/index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RequiredAuthProvider, RedirectToLogin } from "@propelauth/react";

import { EmailView } from "./pages/Email/EmailView";
import { InboxView } from "./pages/Inbox/InboxView";
import { DomainView } from "./pages/Domain/DomainView";
import { ServicesProviderWrapper } from "./components/ServicesProviderWrapper";
import { Toaster } from "./components/ui/sonner";
import { TooltipProvider } from "./components/ui/tooltip";
import { UserProvider } from "./contexts/UserContext";
import { OutboundEmailView } from "./pages/OutboundEmail/OutboundEmail";
import { ApiKeyView } from "./pages/UserSecrets/ApiKeyView";
import { WebhookSigningSecretView } from "./pages/UserSecrets/WebhookSigningSecretView";

const container = document.getElementById("root");
const root = createRoot(container!);

export const App = () => {
  return (
    <ServicesProviderWrapper>
      <TooltipProvider delayDuration={0}>
        <BrowserRouter>
          <UserProvider>
            <Routes>
              <Route path="/" element={<EmailView />} />
              <Route path="/outbound" element={<OutboundEmailView />} />
              <Route path="/inbox" element={<InboxView />} />
              <Route path="/domain" element={<DomainView />} />
              <Route path="/api-keys" element={<ApiKeyView />} />
              <Route
                path="/webhook-signing-secrets"
                element={<WebhookSigningSecretView />}
              />
            </Routes>
          </UserProvider>
          <Toaster richColors />
        </BrowserRouter>
      </TooltipProvider>
    </ServicesProviderWrapper>
  );
};

const authUrl = import.meta.env.VITE_AUTH_URL;

root.render(
  <RequiredAuthProvider
    authUrl={authUrl}
    displayIfLoggedOut={
      <RedirectToLogin postLoginRedirectUrl={window.location.href} />
    }
  >
    <App />
  </RequiredAuthProvider>
);
