import { createContext, useContext, useEffect, useState } from "react";
import {
  ApiKey,
  InboxConfiguration,
  UserInfo,
  WebhookSigningSecret,
} from "../types";
import { useAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/react";
import {
  getApiKeys,
  getInboxConfigurations,
  getUserInfo,
  getWebhookSigningSecrets,
} from "@/utils/apiCalls";
import { toast } from "sonner";

interface UserContextProps {
  userInfo: UserInfo | null;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
  inboxes: InboxConfiguration[] | null;
  setInboxes: React.Dispatch<React.SetStateAction<InboxConfiguration[] | null>>;
  inboxLoading: boolean;
  webhookSigningSecrets: WebhookSigningSecret[];
  setWebhookSigningSecrets: React.Dispatch<
    React.SetStateAction<WebhookSigningSecret[]>
  >;
  loadWebhookSigningSecrets: () => Promise<void>;
  apiKeys: ApiKey[];
  setApiKeys: React.Dispatch<React.SetStateAction<ApiKey[]>>;
}

const UserContext = createContext<UserContextProps>({
  inboxes: null,
  setInboxes: () => {},
  inboxLoading: false,
  userInfo: null,
  setUserInfo: () => {},
  webhookSigningSecrets: [],
  setWebhookSigningSecrets: () => {},
  loadWebhookSigningSecrets: () => Promise.resolve(),
  apiKeys: [],
  setApiKeys: () => {},
});

export const UserProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [inboxLoading, setInboxLoading] = useState<boolean>(false);
  const [inboxes, setInboxes] = useState<InboxConfiguration[] | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [webhookSigningSecrets, setWebhookSigningSecrets] = useState<
    WebhookSigningSecret[]
  >([]);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);

  const loadWebhookSigningSecrets = async () => {
    const response = await getWebhookSigningSecrets(
      authInfo.accessToken ?? null
    );
    if (response !== null) {
      setWebhookSigningSecrets(response);
    } else {
      toast.error(
        "Failed to fetch webhook signing secrets, please refresh the page and try again"
      );
    }
  };

  useEffect(() => {
    if (authInfo.isLoggedIn) {
      if (import.meta.env.VITE_ENV === "prod") {
        Sentry.setUser({
          email: authInfo.user!.email,
          id: authInfo.user!.userId,
        });
      }
    }

    setInboxLoading(true);
    getInboxConfigurations(authInfo.accessToken ?? null).then((response) => {
      if (response) {
        setInboxes(response);
      } else {
        toast.error(
          "Failed to fetch inboxes, please refresh the page and try again"
        );
      }
      setInboxLoading(false);
    });

    getUserInfo(authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        setUserInfo(response);
      } else {
        toast.error(
          "Failed to fetch user info, please refresh the page and try again"
        );
      }
    });

    getApiKeys(authInfo.accessToken ?? null).then((response) => {
      if (response) {
        setApiKeys(response);
      } else {
        toast.error(
          "Failed to fetch API keys, please refresh the page and try again"
        );
      }
    });

    loadWebhookSigningSecrets();
  }, [authInfo.isLoggedIn]);

  return (
    <UserContext.Provider
      value={{
        inboxes,
        setInboxes,
        inboxLoading,
        userInfo,
        setUserInfo,
        webhookSigningSecrets,
        setWebhookSigningSecrets,
        loadWebhookSigningSecrets,
        apiKeys,
        setApiKeys,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
