import { ColumnDef } from "@tanstack/react-table";
import { Layout } from "@/components/Layout";
import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/contexts/UserContext";
import { ClickToCopy } from "@/components/ClickToCopy";
import { loadAndFormatDate } from "@/utils/date";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { WebhookSigningSecret } from "@/types";
import { ReloadIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { refreshWebhookSigningSecret } from "@/utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { CoreSecretManager } from "@/components/UserSecret";

export const WebhookSigningSecretView = () => {
  const authInfo = useAuthInfo();
  const { webhookSigningSecrets, setWebhookSigningSecrets } = useUserContext();
  const [activeSecret, setActiveSecret] = useState<string | null>(null);

  const columns: ColumnDef<WebhookSigningSecret>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger>
            <ClickToCopy
              text={row.original.id}
              className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
            />
          </TooltipTrigger>
          <TooltipContent>{row.original.id}</TooltipContent>
        </Tooltip>
      ),
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => <div>{row.original.name}</div>,
    },
    {
      accessorKey: "preview",
      header: "Secret Preview",
      cell: ({ row }) => <div>{row.original.webhook_secret_preview}</div>,
    },
    {
      accessorKey: "updated",
      header: "Last Updated",
      cell: ({ row }) => (
        <div>{loadAndFormatDate(row.original.updated_at)}</div>
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const [refreshingSecret, setRefreshingSecret] = useState(false);

        const handleRefreshSecret = async (secretId: string | null) => {
          setRefreshingSecret(true);
          const response = await refreshWebhookSigningSecret(
            secretId,
            authInfo.accessToken ?? null,
            row.original.name
          );
          if (response) {
            setActiveSecret(response.secret_value);
            setWebhookSigningSecrets((prev) => {
              const newSecrets = prev?.map((secret) =>
                secret.id === secretId ? response.secret_object : secret
              );
              return newSecrets;
            });
          } else {
            toast.error("Failed to refresh secret");
          }
          setRefreshingSecret(false);
        };

        return (
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="secondary"
                size="icon"
                className="h-6 w-6"
                onClick={() => handleRefreshSecret(row.original.id)}
                disabled={refreshingSecret}
              >
                <ReloadIcon
                  className={cn("w-4 h-4", refreshingSecret && "animate-spin")}
                />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Regenerate Secret</TooltipContent>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Layout title="Webhook Signing Secrets">
      <CoreSecretManager<WebhookSigningSecret>
        name="Webhook Signing Secret"
        description="Add a webhook signing secret to secure your webhook endpoints. This secret will be used to sign the webhook payloads we send to your endpoints, allowing you to verify that the requests are coming from us."
        onSubmitCallback={refreshWebhookSigningSecret}
        dataTable={
          webhookSigningSecrets && webhookSigningSecrets.length > 0 ? (
            <DataTable data={webhookSigningSecrets} columns={columns} />
          ) : undefined
        }
        setSecrets={setWebhookSigningSecrets}
        activeSecret={activeSecret}
        setActiveSecret={setActiveSecret}
      />
    </Layout>
  );
};
