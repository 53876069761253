import { ColumnDef } from "@tanstack/react-table";
import { Layout } from "@/components/Layout";
import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/contexts/UserContext";
import { ClickToCopy } from "@/components/ClickToCopy";
import { loadAndFormatDate } from "@/utils/date";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ApiKey, WebhookSigningSecret } from "@/types";
import { ReloadIcon, TrashIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { useState } from "react";
import {
  createApiKey,
  deleteApiKey,
  refreshWebhookSigningSecret,
} from "@/utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { CoreSecretManager } from "@/components/UserSecret";

export const ApiKeyView = () => {
  const authInfo = useAuthInfo();
  const { apiKeys, setApiKeys } = useUserContext();
  const [activeSecret, setActiveSecret] = useState<string | null>(null);

  const columns: ColumnDef<ApiKey>[] = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger>
            <ClickToCopy
              text={row.original.id}
              className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
            />
          </TooltipTrigger>
          <TooltipContent>{row.original.id}</TooltipContent>
        </Tooltip>
      ),
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => <div>{row.original.name}</div>,
    },
    {
      accessorKey: "preview",
      header: "Secret Preview",
      cell: ({ row }) => <div>{row.original.api_key_preview}</div>,
    },
    {
      accessorKey: "created_at",
      header: "Created At",
      cell: ({ row }) => (
        <div>{loadAndFormatDate(row.original.created_at)}</div>
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const [deletingKey, setDeletingKey] = useState(false);

        const handleDeleteKey = async (secretId: string) => {
          setDeletingKey(true);
          const response = await deleteApiKey(
            secretId,
            authInfo.accessToken ?? null
          );
          if (response) {
            setApiKeys((prev) => prev?.filter((key) => key.id !== secretId));
          } else {
            toast.error("Failed to delete key");
          }
          setDeletingKey(false);
        };

        return (
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="destructive"
                size="icon"
                className="h-6 w-6"
                onClick={() => handleDeleteKey(row.original.id)}
                disabled={deletingKey}
              >
                {deletingKey ? (
                  <ReloadIcon className={cn("w-4 h-4 animate-spin")} />
                ) : (
                  <TrashIcon className={cn("w-4 h-4")} />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>Delete Key</TooltipContent>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Layout title="API Keys">
      <CoreSecretManager<ApiKey>
        name="API Key"
        description="Create an API key"
        onSubmitCallback={(_, accessToken, name) =>
          createApiKey(name, accessToken ?? null)
        }
        dataTable={
          apiKeys && apiKeys.length > 0 ? (
            <DataTable data={apiKeys} columns={columns} />
          ) : undefined
        }
        setSecrets={setApiKeys}
        activeSecret={activeSecret}
        setActiveSecret={setActiveSecret}
      />
    </Layout>
  );
};
