export interface ControlFromAddress {
  addresses: string[];
  domains: string[];
}

export interface InboxConfigurationBase {
  id: string | null;
  name: string;
  email_address: string;
  allow_cc: boolean;
  allow_bcc: boolean;
  spf_pass_required: boolean;
  dkim_pass_required: boolean;
  dmarc_pass_required: boolean;
  allowed_from_addresses: ControlFromAddress | null;
  blocked_from_addresses: ControlFromAddress | null;
  webhook_url: string | null;
  webhook_signing_secret_id: string | null;
  open_and_click_tracking: boolean;
}

export interface InboxConfiguration extends InboxConfigurationBase {
  id: string;
  updated_at: string;
}

export interface EmailWebhookLog {
  id: string;
  status_code: number | null;
  response_body: string | null;
  created_at: string;
}

export type EmailStatus =
  | "valid"
  | "invalid_to_address"
  | "invalid_from_address"
  | "spf_fail"
  | "dkim_fail"
  | "dmarc_fail";

export interface EmailAddress {
  address: string;
  name: string | null;
}

export interface EmailOverview {
  id: string;
  status: EmailStatus;
  inbox_id: string;
  cc_addresses: EmailAddress[];
  bcc_addresses: EmailAddress[];
  reply_to_addresses: EmailAddress[];
  to_addresses: EmailAddress[];
  from_address: EmailAddress;
  subject: string;
  date: string;
  latest_webhook_log: EmailWebhookLog | null;
  status_message: string | null;
  user_mark_status: boolean;
}

export type MessageType = "forward" | "reply" | "new";

export interface OutboundEmailEvent {
  event_type: string;
  date: string;
}

export type OutboundEmailStatus = "pending" | "sent";

export interface OutboundEmailOverview {
  id: string;
  status: OutboundEmailStatus;
  inbox_id: string;
  cc_addresses: EmailAddress[];
  bcc_addresses: EmailAddress[];
  reply_to_addresses: EmailAddress[];
  to_addresses: EmailAddress[];
  from_address: EmailAddress;
  subject: string;
  date: string;
  message_type: MessageType;
  open_and_click_tracking: boolean;
  latest_event: OutboundEmailEvent | null;
}

export interface EmailMetadata {
  to_addresses: EmailAddress[];
  cc_addresses: EmailAddress[];
  bcc_addresses: EmailAddress[];
  reply_to_addresses: EmailAddress[];
  from_address: EmailAddress;
  subject: string;
  date: string;
}

export interface EmailAttachment {
  id: string;
  filename: string;
  content_type: string;
  content_encoding: string | null;
  content_id: string | null;
}

export interface EmailContent {
  plain_text: string | null;
  html: string | null;
  alternative_content: boolean;
  attachments: EmailAttachment[];
}

export interface EmailPayload extends EmailMetadata, EmailContent {
  id: string;
}

export interface ApiKey {
  id: string;
  name: string;
  created_at: string;
  api_key_preview: string;
}

export enum DNSRecordStatus {
  success = "SUCCESS",
  pending = "PENDING",
  failed = "FAILED",
  not_started = "NOT_STARTED",
  temporary_failure = "TEMPORARY_FAILURE",
  optional = "OPTIONAL",
}

export interface DomainDNSRecord {
  type: string;
  name: string;
  value: string;
  priority: number | null;
  status: DNSRecordStatus;
}

export interface Domain {
  id: string;
  domain: string;
  dns_records: DomainDNSRecord[];
  owner_user_id: string;
  created_at: string;
}

type UserPlan = "free" | "pro";

export interface UserInfo {
  id: string;
  email: string;
  plan: UserPlan;
  sent_email_count_rate_limit: number;
  inbox_count_rate_limit: number | null;
  custom_domain_rate_limit: number | null;
}

export interface OutboundEmailMetadata {
  inbox_count: Record<string, number>;
}

export interface InboundEmailMetadata {
  inbox_count: Record<string, number>;
  from_address_count: Record<string, number>;
}

export interface WebhookSigningSecret {
  id: string;
  name: string;
  updated_at: string;
  webhook_secret_preview: string;
}
