import { Button } from "@/components/ui/button";
import { PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useIsMobile } from "@/hooks/use-mobile";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { SecretPopup } from "./SecretDialog";

const secretFormSchema = z.object({
  name: z.string(),
});

const SecretForm = <T extends object>(props: {
  onSubmitCallback: (
    secretId: string | null,
    accessToken: string | null,
    name: string
  ) => Promise<{
    secret_value: string;
    secret_object: T;
  } | null>;
  onSubmitSuccessCallback: (secret_value: string, secret_object: T) => void;
}) => {
  const authInfo = useAuthInfo();
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof secretFormSchema>>({
    resolver: zodResolver(secretFormSchema),
    defaultValues: {
      name: "Prod",
    },
  });

  const onSubmit = async (data: z.infer<typeof secretFormSchema>) => {
    setLoading(true);
    const response = await props.onSubmitCallback(
      null,
      authInfo.accessToken ?? null,
      data.name
    );
    if (response) {
      toast.success("Secret created successfully");
      if (props.onSubmitSuccessCallback) {
        props.onSubmitSuccessCallback(
          response.secret_value,
          response.secret_object
        );
      }
    } else {
      toast.error("Failed to create secret");
    }
    setLoading(false);
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={loading}>
          Create
          {loading && <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />}
        </Button>
      </form>
    </Form>
  );
};

const AddSecretDialog = (props: {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
}) => {
  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="h-4 w-4 mr-2" /> Create New
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`Create New ${props.name}`}</DialogTitle>
        </DialogHeader>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

const AddSecretDrawer = (props: {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
}) => {
  return (
    <Drawer open={props.open} onOpenChange={props.setOpen}>
      <DrawerTrigger asChild>
        <Button>
          <PlusIcon className="h-4 w-4 mr-2" /> Create New
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>{`Create New ${props.name}`}</DrawerTitle>
        </DrawerHeader>
        <div className="p-4">{props.children}</div>
      </DrawerContent>
    </Drawer>
  );
};

const AddSecretPopup = <T extends object>(props: {
  name: string;
  onSubmitCallback: (
    secretId: string | null,
    accessToken: string | null,
    name: string
  ) => Promise<{
    secret_value: string;
    secret_object: T;
  } | null>;
  onSubmitSuccessCallback: (secret_value: string, secret_object: T) => void;
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleSubmitSuccess = (secret_value: string, secret_object: T) => {
    setOpen(false);
    props.onSubmitSuccessCallback(secret_value, secret_object);
  };

  const children = (
    <SecretForm<T>
      onSubmitCallback={props.onSubmitCallback}
      onSubmitSuccessCallback={handleSubmitSuccess}
    />
  );

  return isMobile ? (
    <AddSecretDrawer
      name={props.name}
      open={open}
      setOpen={setOpen}
      children={children}
    />
  ) : (
    <AddSecretDialog
      name={props.name}
      open={open}
      setOpen={setOpen}
      children={children}
    />
  );
};

const EmptySecretState = <T extends object>(props: {
  name: string;
  description: string;
  onSubmitCallback: (
    secretId: string | null,
    accessToken: string | null,
    name: string
  ) => Promise<{
    secret_value: string;
    secret_object: T;
  } | null>;
  onSubmitSuccessCallback: (secret_value: string, secret_object: T) => void;
}) => {
  return (
    <div className="flex justify-center">
      <Card className="w-[600px]">
        <CardHeader>
          <CardTitle>{props.name}</CardTitle>
          <CardDescription>{props.description}</CardDescription>
        </CardHeader>
        <CardContent>
          <SecretForm<T>
            onSubmitCallback={props.onSubmitCallback}
            onSubmitSuccessCallback={props.onSubmitSuccessCallback}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export const CoreSecretManager = <T extends object>(props: {
  name: string;
  description: string;
  onSubmitCallback: (
    secretId: string | null,
    accessToken: string | null,
    name: string
  ) => Promise<{
    secret_value: string;
    secret_object: T;
  } | null>;
  setSecrets: React.Dispatch<React.SetStateAction<T[]>>;
  activeSecret: string | null;
  setActiveSecret: React.Dispatch<React.SetStateAction<string | null>>;
  dataTable?: React.ReactNode;
}) => {
  const onSubmitSuccessCallback = (secret_value: string, secret_object: T) => {
    props.setActiveSecret(secret_value);
    props.setSecrets((prev) =>
      prev ? [secret_object, ...prev] : [secret_object]
    );
  };

  return (
    <>
      <SecretPopup
        secret={props.activeSecret}
        setSecret={props.setActiveSecret}
        secretName={props.name}
      />
      <div className="space-y-4">
        <div className="flex justify-end">
          <AddSecretPopup<T>
            name={props.name}
            onSubmitCallback={props.onSubmitCallback}
            onSubmitSuccessCallback={onSubmitSuccessCallback}
          />
        </div>
        {props.dataTable ? (
          props.dataTable
        ) : (
          <EmptySecretState<T>
            name={props.name}
            description={props.description}
            onSubmitCallback={props.onSubmitCallback}
            onSubmitSuccessCallback={onSubmitSuccessCallback}
          />
        )}
      </div>
    </>
  );
};
